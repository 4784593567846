import React from "react";
import { Colors } from "@helpers/Colors";
import { useWindowSize } from "@helpers/Hooks";
import { TypographyV2 } from "..";
import { Variant } from "@components/TypographyV2/TypographyV2";
import classNames from "classnames";
import * as styles from "./Section-title.module.scss";

type SectionTitleProps = {
	title: React.ReactNode;
	subtitle?: React.ReactNode;
	titleFont?: Variant;
	subtitleFont?: Variant;
	textColor: Colors;
	marginTop?: number;
	marginBottom?: number;
	marginTopMobile?: number;
	marginTopTablet?: number;
	marginBottomMobile?: number;
	marginBottomTablet?: number;
	titleWidth?: number;
	subtitleWidth?: number;
	applyV4Styles?: boolean;
	subtitleTextColor?: Colors;
	subtitleOverride?: string;
};

export const SectionTitle = ({
	title,
	subtitle,
	titleFont = "HEADING_2",
	subtitleFont = "BODY_TEXT_EXTRA_LARGE",
	textColor,
	marginTop = 74,
	marginBottom = 36,
	marginTopMobile = 36,
	marginTopTablet = 140,
	marginBottomMobile = 36,
	marginBottomTablet = 36,
	titleWidth = 1088,
	subtitleWidth = 1088,
	applyV4Styles = false,
	subtitleTextColor,
	subtitleOverride,
}: SectionTitleProps) => {
	const { isMobile, isTablet } = useWindowSize();
	return (
		<div
			style={{
				marginTop: isTablet
					? isMobile
						? marginTopMobile
						: marginTopTablet
					: marginTop,
				marginBottom: isTablet
					? isMobile
						? marginBottomMobile
						: marginBottomTablet
					: marginBottom,
				textAlign: "center",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			{title && (
				<TypographyV2
					variant={titleFont}
					color={textColor}
					className={classNames({ [styles.titleV4]: applyV4Styles })}
					style={{
						maxWidth: isTablet
							? Math.min(titleWidth, 684)
							: titleWidth,
						margin: "auto",
					}}
				>
					{title}
				</TypographyV2>
			)}
			{subtitle && (
				<TypographyV2
					variant={subtitleFont}
					color={subtitleTextColor ?? textColor}
					className={classNames(styles.subtitle, subtitleOverride, {
						[styles.subtitleV4]: applyV4Styles,
					})}
					style={{
						maxWidth: isTablet
							? Math.min(subtitleWidth, 684)
							: subtitleWidth,
					}}
				>
					{subtitle}
				</TypographyV2>
			)}
		</div>
	);
};
